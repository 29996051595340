.docking-subheader {
    background-color: #4C375A;
    color: white;
    height: 50px;
    width: 100%;
    -webkit-box-shadow: 0 1px 8px rgba(0,0,0,.3);
    box-shadow: 0 1px 8px rgba(0,0,0,.3);
    padding: 0;
    z-index: 8;
    position: fixed;
    top: 50px;
    transition: all .4s;
}

.docking-subheader.docked {
    top: -100px;
}
