.App > section {
  padding: 0 1.75em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.floating-app-header {
  background-color: #654976;
  color: white;
  height: 50px;
  width: 100%;
  -webkit-box-shadow: 0 1px 8px rgba(0,0,0,.3);
  box-shadow: 0 1px 8px rgba(0,0,0,.3);
  padding: 0;
  z-index: 10;
  position: fixed;
  top: 0px;
  transition: all .2s;
}

.dockable-header .floating-app-header.docked {
  top: -60px;
}

.App-header {
  background-color: #654976;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-bumper {
  background: #4C375A;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.header-bumper div {
  display: flex;
}

.App-link {
  color: #61dafb;
}